var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"set-room-card",attrs:{"title":("发放房卡-" + (_vm.title.dormitoryName) + (_vm.title.floorNum) + "层" + (_vm.title.roomName) + "【" + (_vm.title.dormitoryRoomType?_vm.title.dormitoryRoomType.roomTypeName:'--') + "】"),"visible":_vm.show,"close-on-click-modal":false,"width":"820px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{on:{"click":_vm.close}},[_vm._v("关闭")])]},proxy:true}])},[_c('el-tabs',{model:{value:(_vm.showType),callback:function ($$v) {_vm.showType=$$v},expression:"showType"}},[_c('el-tab-pane',{attrs:{"label":"发卡记录","name":"record"}},[_c('el-table',{attrs:{"data":_vm.tableData,"height":"400px"}},[_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuName || row.username)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"设置时间","width":"160"}}),_c('el-table-column',{attrs:{"prop":"serialNumber","label":"流水号","align":"center"}}),_c('el-table-column',{attrs:{"label":"卡状态","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.enableFlag?'success':'info'}},[_vm._v(_vm._s(row.enableFlag ? '启用' : '禁用'))])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tool-tips-group',{attrs:{"tips-content":" <b>**功能操作前提**：</b><br>\n            启用或禁用功能时，需确保NFC读卡器处于工作状态。<br>\n             <b> - **操作流程**：</b><br>\n                1. 将空白卡片放置于NFC读卡器上。<br>\n                2. 成功执行操作后，卡片将被写入特定数据。<br>\n                3. 最后，需使用已写入数据的卡片在宿舍门锁上进行刷卡操作。<br>\n              <b>- **效果达成**：</b><br>\n              完成上述步骤后，功能启用或禁用状态将在门锁中生效。"}},[_vm._v("是否启用 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.enableFlag)?[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.handleSwitch(row,0)}}},[_vm._v(" 禁用 ")])]:_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleSwitch(row,1)}}},[_vm._v(" 启用 ")])]}}])})],1),_c('div',{staticClass:"pagination-body table-view__footer"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-size":_vm.queryInfo.pageSize,"page-sizes":[10,20,40,60,100],"total":_vm.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1),_c('el-tab-pane',{attrs:{"label":"发卡","name":"send"}},[_c('div',{staticClass:"tip"},[_vm._v(" 1.学生从为绑定本宿舍门卡的，可点击发卡发放新门卡； "),_c('br'),_vm._v(" 2.退卡后，可点击补办重新发放门卡； ")]),_c('el-table',{attrs:{"data":_vm.dormUserList,"border":"","stripe":"","height":"400px"}},[_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuName || row.realName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.bindStudent(row)}}},[_vm._v("发卡")]),_c('el-button',{on:{"click":function($event){return _vm.bindStudent(row,1)}}},[_vm._v(" 补办 ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }