<template>
  <el-dialog
    class="set-room-card"
    :title="`发放房卡-${title.dormitoryName}${title.floorNum}层${title.roomName}【${title.dormitoryRoomType?title.dormitoryRoomType.roomTypeName:'--'}】`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="820px"
  >
    <el-tabs v-model="showType">
      <el-tab-pane label="发卡记录" name="record">
        <el-table :data="tableData" height="400px">
          <el-table-column label="姓名">
            <template v-slot="{row}">
              {{ row.stuName || row.username }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="设置时间" width="160" />
          <el-table-column prop="serialNumber" label="流水号" align="center" />
          <el-table-column label="卡状态" width="100">
            <template v-slot="{row}">
              <el-tag :type="row.enableFlag?'success':'info'">{{ row.enableFlag ? '启用' : '禁用' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <tool-tips-group tips-content=" <b>**功能操作前提**：</b><br>
              启用或禁用功能时，需确保NFC读卡器处于工作状态。<br>
               <b> - **操作流程**：</b><br>
                  1. 将空白卡片放置于NFC读卡器上。<br>
                  2. 成功执行操作后，卡片将被写入特定数据。<br>
                  3. 最后，需使用已写入数据的卡片在宿舍门锁上进行刷卡操作。<br>
                <b>- **效果达成**：</b><br>
                完成上述步骤后，功能启用或禁用状态将在门锁中生效。">是否启用
              </tool-tips-group>
            </template>
            <template v-slot="{row}">
              <template v-if="row.enableFlag">
                <el-button type="danger" @click="handleSwitch(row,0)">
                  禁用
                </el-button>
              </template>
              <el-button v-else type="text" @click="handleSwitch(row,1)">
                启用
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-body table-view__footer">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-size="queryInfo.pageSize"
            :page-sizes="[10,20,40,60,100]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="发卡" name="send">
        <div class="tip">
          1.学生从为绑定本宿舍门卡的，可点击发卡发放新门卡； <br>
          2.退卡后，可点击补办重新发放门卡；
        </div>
        <el-table :data="dormUserList" border stripe height="400px">
          <el-table-column label="姓名">
            <template v-slot="{row}">
              {{ row.stuName || row.realName }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template v-slot="{row}">
              <el-button type="primary" @click="bindStudent(row)">发卡</el-button>
              <el-button @click="bindStudent(row,1)">
                补办
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { bindDormCardByUserIdApi, pageLockCardByCodeApi } from '@/api/dorm/lock-api'
import dialog from '@/vue/mixins/dialog'
import nfcCard from '@/vue/mixins/nfc-card.js'
import { intersection } from 'lodash'

export default {
  name: 'bindStudentDialog',
  mixins: [dialog, nfcCard],
  data() {
    return {
      showType: 'send',
      loading: false,
      dormUserList: [],
      parentData: {},
      total: 0,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        hasUserFlag: 1,
        featureCode: null
      },
      tableData: []
    }
  },
  async mounted() {
    await this.$nextTick()
    await this.getDormStudentList()
    await this.renderTable()
  },
  computed: {
    // 判断是否楼栋管理员or超级管理员
    isEditor() {
      let _editor = false
      // dormitoryIdList 里存在本宿舍楼id时，代表为本楼栋管理员，可以编辑,否则按权限分配
      if (this.currentFloor?.dormitoryInfoId && this.$store.state.login.userInfo?.dormitoryIdList?.length) {
        _editor = (this.$store.state.login.userInfo.dormitoryIdList || []).includes(this.currentFloor.dormitoryInfoId)
      }
      return _editor
    }
  },
  methods: {
    // 拥有编辑权限的可以操作数据
    hasPermiKeys(arr) {
      return this.isEditor || intersection(this.$store.state.login.userInfo.paraNameList, arr)?.length
    },
    async renderTable() {
      try {
        this.loading = true
        this.queryInfo.featureCode = this.title.lockFeatureCode
        const res = await pageLockCardByCodeApi(this.queryInfo)
        this.tableData = res.data.list
        this.total = res.data.total
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 根据宿舍id获取已绑定宿舍学生/教职工列表
    async getDormStudentList() {
      try {
        const res = await this.$http.getDormRoomBedListQuery({
          pageNum: 1,
          pageSize: 50,
          dormitoryRoomId: this.id
        })
        this.dormUserList = res.data.filter(item => !!(item.stuName || item.realName))
      } catch (e) {
        console.error(e)
      }
    },
    handleSwitch(row, flag) {
      this.$confirm(`<p classs="confirm-message">是否确定${flag ? '<b class="bg-primary">启用</b>' : '<b class="primary">禁用</b>'}此门卡？</p>`, '提示', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          this.loading = true
          // 把数据存入enableRow ，写入失败后把状态返回为原来的
          this.enableFlag = true
          this.enableRow = JSON.parse(JSON.stringify(row))
          this.enableRow.enableFlag = flag ? 0 : 1
          // 开始修改状态
          const _key = await this.handleEnable(row, flag)
          // 把返回的信息写入nfc卡
          const d = '00000000000000000000000000000000' + _key
          this.readNfc(d)
          this.$message({
            message: `${flag ? '启用' : '禁用'}成功，数据正在写入NFC卡，请稍等。`,
            type: flag ? 'success' : 'warning'
          })
        } catch (e) {
          await this.handleEnable(row, row.enableFlag)
          console.error(e)
        } finally {
          await this.renderTable()
          this.loading = false
        }
      }).catch(() => {
        this.$message.info('已取消')
      })
    },
    // 发卡（绑定学生）
    async bindStudent(dromBed, reSendFlag = null) {
      if (reSendFlag) {
        this.message = '补办成功！'
      }
      if (!this.title.lockInfo) {
        this.$message.info('未设置门锁！')
      } else if (!this.title.lockInfo.lockType) {
        this.$message.info('旧锁不提供绑定nfc的功能！')
      } else {
        try {
          this.loading = true
          const res = await bindDormCardByUserIdApi({
            aalockId: this.title.lockInfo.id,
            userId: dromBed.userId,
            reSendFlag: reSendFlag
          })
          const d = '00000000000000000000000000000000' + res.data
          this.readNfc(d)
        } catch (e) {
          console.error(e)
        } finally {
          await this.renderTable()
          this.loading = false
        }
      }
    },
    async handleSizeChange(size) {
      this.queryInfo.pageSize = size
      await this.renderTable()
    },
    async handleCurrentChange(page) {
      this.queryInfo.pageNum = page
      await this.renderTable()
    }
  }
}
</script>
