<!--发放房卡-->
<template>
  <el-container v-loading="loading" class="set-room-card flex-column">
    <el-container class="card flex-1">
      <el-aside class="flex" width="220px">
        <div class="table-warp flex-1">
          <el-table
            ref="navTableRef"
            :data="dormTree"
            style="width: 100%;"
            height="100%"
            row-key="id"
            border
            lazy
            @row-click="handleRowClick"
          >
            <el-table-column label="校区/楼层" width="180" show-overflow-tooltip>
              <template #default="scope">
                <span
                  :class="[{'is-active': scope.row.id === currentFloor.id},{'is-floor':!scope.row.children}]">{{ scope.row.label
                  }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-aside>
      <el-main class="flex-1 flex-column" style="padding: 10px 10px 10px 0; ">
        <header class="card-header" v-if="currentFloor.parentData">
          <b>{{ currentFloor.parentData.label }}</b>-
          <b>{{ currentFloor.label }}</b>
          <div class="fr">
            <!--  <el-popover-->
            <!--    placement="left"-->
            <!--    title="门卡设置"-->
            <!--    width="300"-->
            <!--    trigger="hover">-->
            <!--    <div>-->
            <!--    </div>-->
            <!--    <el-button slot="reference" size="small" icon="el-icon-more" circle />-->
            <!--  </el-popover>-->
            <el-tooltip>
              <template #content>
                通过<b>NFC设备</b>把校区信息写入智能锁，然后通过刷卡录入到智能锁。 <br>
                授权卡作为<b>配置工具</b>，仅限于管理及更新门锁的授权，因此<b>不具备开锁功能</b>。
              </template>
              <el-button class="mr-1" v-if="hasPermiKeys(['aaLock:card:access'])" type="purple" plain size="small"
                         @click="setCard('Access','(校区)授权卡')">
                写入授权信息
              </el-button>
            </el-tooltip>
            <el-dropdown>
              <el-button v-permission="['aaLock:card:school']" class="ml-1 mr-1" type="primary" plain size="small"
                         style="padding-top: 5px;padding-bottom: 5px;transform:translateY(2px)">
                <tool-tips-group is-left tips-content="可开当前<b>学校内</b>的所有宿舍门锁"><span
                  style="vertical-align: -4px">总控卡
                  <i class="el-icon-arrow-down"></i></span>
                </tool-tips-group>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setCard('campus','总控卡',0)">不可开反锁门</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="setCard('campus','总控卡',1)">可开反锁门</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <el-button v-permission="['aaLock:card:building']" class="ml-1 mr-1" type="primary" plain size="small"
                         style="padding-top: 5px;padding-bottom: 5px;transform:translateY(2px)">
                <tool-tips-group is-left tips-content="可开当前<b>宿舍楼</b>的所有宿舍门锁。"><span
                  style="vertical-align: -4px">发宿舍楼卡
                  <i class="el-icon-arrow-down"></i></span>
                </tool-tips-group>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setCard('building',`宿舍楼卡(${currentFloor.parentData.label})`,0)">不可开反锁门</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="setCard('building',`宿舍楼卡(${currentFloor.parentData.label})`,1)">可开反锁门</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-tooltip content="可开当前宿舍楼楼层的所有宿舍门锁。">
              <el-button v-if="hasPermiKeys(['aaLock:card:floor'])" type="primary" plain size="small"
                         @click="setCard('floor',`层卡(${currentFloor.parentData.label}${currentFloor.label})`)">
                发层卡
              </el-button>
            </el-tooltip>
            <!--            <el-button v-permission="['aaLock:card:team']" type="primary" @click="setCard('team')">激活组卡</el-button>-->
          </div>
        </header>
        <div class="table-warp flex-1">
          <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%;"
            height="100%"
            row-key="id"
            :tree-props="{children: 'children',hasChildren:'hasChildren'}"
            border
            lazy
          >
            <el-table-column prop="roomName" label="宿舍号" width="140" align="center" />
            <el-table-column prop="dormitoryRoomType.roomTypeName" label="类型" min-width="160" align="center" />
            <el-table-column prop="lockFeatureCode" label="锁ID" min-width="180">
              <template #default="{row}">
                {{ row.lockFeatureCode || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="lockFeatureCode" label="是否新锁" min-width="120" align="center">
              <template #default="{row}">
                <template v-if="row.lockInfo">
                  <el-tag v-if="row.lockInfo.lockType">是</el-tag>
                  <el-tag v-else type="info">否</el-tag>
                </template>
                <span v-else class="font-grey">未设置</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="370px" fixed="right">
              <template #default="{row}">
                <el-button v-if="hasPermiKeys(['aaLock:room:edit'])" type="primary" size="mini"
                           @click="editLockCode(row)">设置门锁码
                </el-button>
                <!--存在锁且新锁才有nfc功能 row.lockType==1-->
                <template v-if="row.lockInfo&&row.lockInfo.lockType===1">
                  <el-tooltip effect="dark" placement="top">
                    <template #content>
                      通过<b>NFC设备</b>把校区、宿舍楼、楼层及宿舍号录入到卡片，然后通过刷卡录入到智能锁。 <br>
                      此卡作为<b>配置工具</b>，仅限于设置门锁信息，因此<b>不具备开锁功能</b>。
                    </template>
                    <el-button v-if="hasPermiKeys(['aaLock:card:set'])" type="purple" size="mini" @click="activateCard(row)">
                      写入门锁信息
                    </el-button>
                  </el-tooltip>
                  <el-button v-show="row" v-if="hasPermiKeys(['aaLock:card:student'])" type="primary" size="mini"
                             @click="showDialog(row.id,row)">发卡（绑定{{ row.inRule === 4 ? '教职工' : '学生' }}）
                  </el-button>
                  <!-- <el-tooltip effect="dark" content="设置此卡，可以退所有宿舍的门卡，因此请谨慎操作。" placement="top">-->
                  <!--   <el-button v-permission="['aaLock:card:student']" type="primary" size="mini"-->
                  <!--              @click="clearDormCard(row)">退卡-->
                  <!--   </el-button>-->
                  <!-- </el-tooltip>-->
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
    </el-container>
    <bind-student-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" />
  </el-container>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { getDormTree, getDormRoomById, getDormTreeByManagerApi, saveLockFeatureCodeApi } from '@/api/dorm'
import nfcCard from '@/vue/mixins/nfc-card.js'
import {
  activateCardForAccessApi,
  activateCardForBuildingApi, activateCardForCampusApi,
  activateCardForFloorApi,
  activateCardForTeamApi,
  activateDormCardApi, clearDormCardApi
} from '@/api/dorm/lock-api'
import BindStudentDialog from '@/views/pages/dormManage/aalock/set-aalock-card/dialog/bindStudentDialog.vue'
import { cloneDeep, intersection } from 'lodash'

export default {
  name: 'setAALockCard',
  components: { BindStudentDialog },
  mixins: [nfcCard, tableView],
  data() {
    return {
      campusList: [], // 校区
      // 左侧树
      dormTree: [],
      aaLockCampusList: [], // aalock对应的校区List
      // 选中的楼层
      currentFloor: {},
      curBuild: [], // 选中的楼栋
      isExpand: false,
      dormTreeApi: null // 根据角色选择api
    }
  },
  async mounted() {
    // 获取
    await this.getAALockCampusIdList()
    // this.dormTreeApi = this.$store.state.login.isAdmin ? getDormTree : getDormTreeByManagerApi
    await this.getTree()
    await this.$nextTick()
    this.$refs.navTableRef.setCurrentRow(this.dormTree[0].children[0])
  },
  computed: {
    // 判断是否楼栋管理员or超级管理员
    isEditor() {
      let _editor = false
      // dormitoryIdList 里存在本宿舍楼id时，代表为本楼栋管理员，可以编辑,否则按权限字段分配
      if (this.currentFloor?.dormitoryInfoId && this.$store.state.login.userInfo?.dormitoryIdList?.length) {
        _editor = (this.$store.state.login.userInfo.dormitoryIdList || []).includes(this.currentFloor.dormitoryInfoId)
      }
      return _editor
    }
  },
  methods: {
    // 拥有编辑权限的可以操作数据
    hasPermiKeys(arr) {
      return this.isEditor || intersection(this.$store.state.login.userInfo.paraNameList, arr)?.length
    },
    // 获取侧栏宿舍树
    async getTree() {
      try {
        this.loading = true
        const res = await getDormTree({ campusId: this.queryInfo.campusId })
        // 删除子级，只保留1、2级，因为第三级别宿舍没有锁id，需要通过renderTable另外获取
        this.dormTree = res.data.map((campus) => {
          campus.children.map((floor) => {
            delete floor.children
            floor.floorAmount = campus.floorAmount
            return floor
          })
          return campus
        })
        if (res.data.length) {
          if (res.data[0]?.children.length) {
            const _target = res.data[0]
            this.currentFloor = cloneDeep(_target.children[0])
            this.curBuild = [_target.id]
            // 获取父级信息
            const {
              id,
              label,
              lockNo,
              campusId
            } = _target
            this.currentFloor.parentData = {
              id,
              label,
              lockNo,
              campusId
            }

            // 获取楼层宿舍list
            await this.renderTable(this.currentFloor)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 根据宿舍楼和楼层获取宿舍list
    async renderTable() {
      this.loading = true
      try {
        // hasElectric=0 非 电表模块
        const query = {
          dormitoryId: this.currentFloor.dormitoryInfoId,
          floorNum: this.currentFloor.floorNum,
          hasLock: 1,
          hasElectric: 0
        }
        const res = await this.$http.getDormRoomQuery(query)
        // 本楼层宿舍，用于获取门锁id和开卡
        this.tableData = res.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 点击侧栏，展开or选中楼层加载数据
    async handleRowClick(row) {
      if (row.nodeType === 'dormitoryInfo') {
        // 为楼栋
        this.$refs.navTableRef.toggleRowExpansion(row)
      } else if (row.nodeType === 'dormitoryFloor') {
        // 楼层时
        this.currentFloor = cloneDeep(row)
        const _target = this.dormTree.find((item) => item.id === row.dormitoryInfoId)
        // 获取父级信息
        const {
          id,
          label,
          lockNo,
          campusId
        } = _target
        this.currentFloor.parentData = {
          id,
          label,
          lockNo,
          campusId
        }
        await this.renderTable(row)
      }
    },
    // 写入门锁信息（写入后才能绑定学生）
    async activateCard(row) {
      if (!row.lockInfo) {
        this.$message.info('未设置门锁！')
        return
      } else if (!row.lockInfo.lockType) {
        this.$message.info('旧锁不提供绑定nfc的功能！')
        return
      }
      try {
        this.message = '门锁信息写入成功，刷入门锁后即可进行发卡操作！'
        const res = await activateDormCardApi({
          aalockId: row.lockInfo.id
        })
        const d = '00000000000000000000000000000000' + res.data
        this.readNfc(d)
      } catch (e) {
        console.error(e)
      }
    },
    // 修改门锁设备码
    editLockCode(row) {
      this.$prompt(`请输入门锁设备码(<b>原门锁码：${row.lockFeatureCode ? row.lockFeatureCode : '未设置'}</b>)`, '设置门锁码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputValue: row.lockFeatureCode || '',
        dangerouslyUseHTMLString: true
      }).then(({ value }) => {
        this.$confirm('是否确认修改门锁码？').then(async () => {
          try {
            await saveLockFeatureCodeApi({
              id: row.id,
              lockFeatureCode: value
            })
            this.$message.success(`${row.roomName}门锁设备码修改成功!`)
            await this.renderTable()
          } catch (e) {
            if (!e) {
              this.$message.error('数据获取失败，请重试！')
            }
          }
        })
      }).catch(() => {
      })
    },
    // 获取aalock那边对应的校区id列表（因为那边不同校区属于不同组织）
    async getAALockCampusIdList() {
      try {
        const res = await this.$http.getSysDictionaryByKey('aaLockCampus')
        this.aaLockCampusList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    /*  发卡、设置授权卡/楼卡/层卡/组卡/
    *   type {string}  发卡类型 campus 校园卡 building 楼卡 floor 层卡 team 组卡
    *   name {string}  发卡类型名称, 用于弹窗确认
    *  TrunFlag{number} 是否可以反锁门 1/0
    * */
    setCard(type, name, TrunFlag) {
      this.$confirm(`是否确定设置该卡为<b>${name}${TrunFlag === 0 ? '【不可开反锁门】' : TrunFlag ? '【可开反锁门】' : ''}？<b>`, '发卡确认', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        let apiMethod, apiParams
        const appId = this.aaLockCampusList.find(item => item.paramName === this.currentFloor.parentData.campusId)?.paramValue
        if (!appId) {
          this.$message.error('无法获取校区信息！')
          return
        }
        switch (type) {
          case 'Access':
            this.message = '授权信息已写入，待刷入门锁！'
            apiMethod = activateCardForAccessApi
            apiParams = { appId }
            break
          case 'campus':
            this.message = `总控卡${TrunFlag === 0 ? '【不可开反锁门】' : TrunFlag ? '【可开反锁门】' : ''}发放成功，可开学校内所有宿舍门锁！`
            apiMethod = activateCardForCampusApi
            apiParams = {
              appId,
              isOpenTrunLock: TrunFlag
            }
            break
          case 'building':
            this.message = `宿舍楼卡发放成功，可开 ${this.currentFloor.parentData.label}${TrunFlag === 0 ? '【不可开反锁门】' : TrunFlag ? '【可开反锁门】' : ''}的所有宿舍门锁！`
            apiMethod = activateCardForBuildingApi
            apiParams = {
              appId,
              build: this.currentFloor.parentData.lockNo,
              isOpenTrunLock: TrunFlag
            }
            break
          case 'floor':
            this.message = `层卡发放成功，可开 ${this.currentFloor.parentData.label}-${this.currentFloor.label}的所有宿舍门锁！`
            apiMethod = activateCardForFloorApi
            apiParams = {
              appId,
              build: this.currentFloor.parentData.lockNo,
              floor: this.currentFloor.floorNum
            }
            break
          case 'team':
            this.message = '组卡发放成功！'
            apiMethod = activateCardForTeamApi
            apiParams = {}
            break
        }
        try {
          const res = await apiMethod(apiParams)
          const d = '00000000000000000000000000000000' + res.data
          this.readNfc(d)
        } catch (e) {
          console.error(e)
        }
      }).catch(() => {
        this.$message.info('已取消操作。')
      })
    },
    // 退卡（重置锁）
    clearDormCard(row) {
      this.$confirm(`退卡会导致<b>${row.roomName}</b>的${row.inRule === 4 ? '教职工' : '学生'}房卡<b class="font-danger">全部失效</b>，且无法恢复使用，是否确认退卡？`, '退卡确认', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          this.message = '退宿卡写入成功！此卡可使小区内所有宿舍的门卡失效，请谨慎保管！'
          const res = await clearDormCardApi({
            aalockId: row.lockInfo.id
          })
          const d = '00000000000000000000000000000000' + res.data
          this.readNfc(d)
        } catch (e) {
          console.error(e)
        }
      }).catch(e => {
        console.error(e)
      })
    },
    // 获取宿舍信息（主要是inRule）
    async getDormRoomById(dormitoryRoomId) {
      try {
        const res = getDormRoomById(dormitoryRoomId)
        this.roomInfo = res.data
        this.getDormRoomTypeById(this.roomInfo.roomTypeId)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
<style lang="scss">
.set-room-card {
  padding: 20px;
  height: calc(100vh - var(--header-height));

  .card {
    height: calc(100% - 100px);
    background: #fff;
    border-radius: 8px;
    box-shadow: 1px 2px 2px hsl(0deg 0% 0% / 0.05),
    2px 4px 4px hsl(0deg 0% 0% / 0.05),
    4px 8px 8px hsl(0deg 0% 0% / 0.05),
    8px 16px 16px hsl(0deg 0% 0% / 0.05),
    16px 32px 32px hsl(0deg 0% 0% / 0.05);

    .el-aside {
      height: 100%;
      padding: 10px;
      border-radius: 16px 16px 0 0;

    }

    .card-header {
      padding: 10px;
    }

    .table-warp {
      min-height: 0;
    }

    .el-table {
      .is-floor {
        cursor: pointer;
      }

      .is-active {
        color: var(--color-primary);
      }
    }
  }
}
</style>
